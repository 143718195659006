import { events } from "./state";
import { ethers } from "ethers";
const ethereum = window['ethereum'];
export default class Tools {
    constructor(providers, chainId) {
        this.providers = providers;
        this.NETWORK_CHAIN_ID = chainId;
    }
    createMetamask() {
        this.providers.metamask = new ethers.BrowserProvider(ethereum, "any");
        events.emit('metamask', 'created');
    }
    async connectMetamask() {
        await this.providers.metamask?.getSigner();
        events.emit('metamask', 'connected');
    }
    async disconnectMetamask() {
        await ethereum.request({
            method: 'wallet_revokePermissions',
            "params": [
                {
                    "eth_accounts": {}
                }
            ]
        });
        events.emit('metamask', 'disconnected');
    }
    isMetamaskInstalled() {
        return this.providers.metamask != null;
    }
    async isMetamaskConnected() {
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        return accounts.length > 0;
    }
    async isMetamaskNetworkCorrect() {
        const network = await this.providers.metamask?.getNetwork();
        return network && network.chainId === this.NETWORK_CHAIN_ID;
    }
    async isMetamaskOk() {
        return this.isMetamaskInstalled() && await this.isMetamaskConnected() && await this.isMetamaskNetworkCorrect();
    }
    formatDate(date) {
        // Define arrays for month and day names
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        // Extract components from the date object
        const hours = date.getHours().toString().padStart(2, '0'); // pad hours with leading zero if needed
        const minutes = date.getMinutes().toString().padStart(2, '0'); // pad minutes with leading zero if needed
        const seconds = date.getSeconds().toString().padStart(2, '0'); // pad minutes with leading zero if needed
        const month = months[date.getMonth()]; // get month name
        const day = date.getDate(); // get day of the month
        const year = date.getFullYear(); // get full year
        // Format and return the string
        return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`;
    }
}
