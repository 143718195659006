<template>
  <div class="pre-account">
    <div class="component account">
      <div class="component-title">Account</div>
      <br/>
      <template v-if="account != null">
        <div>
          <b>{{ account.address }}</b>
        </div>
        <br/>
        <div class="tokens" v-if="account.balance">
          <div class="token" data-token="ETH">
            {{ account.balance.eth }} ETH
          </div>
          <div class="token" v-for="token in account.balance.tokens"
               :data-token="token.token.getSymbol()">
            {{ token.balance }} {{ token.token.getSymbol() }}
          </div>
        </div>
      </template>
      <div v-if="metamaskError != null" class="metamaskError">
        {{ metamaskError }}
      </div>
      <br/>
    </div>
    <div class="component-buttons" v-if="!account && metamaskAvailable">
      <div @click="connect()" class="component-button">
        Connect metamask
      </div>
    </div>
    <div class="component-buttons two" v-if="account && !metamaskCorrectNetwork">
      <div @click="changeNetwork()" class="component-button">
        Change network
      </div>
      <div @click="disconnect()" class="component-button">
        Disconnect metamask
      </div>
    </div>
    <div class="component-buttons" v-if="account && metamaskCorrectNetwork">
      <div @click="disconnect()" class="component-button">
        Disconnect metamask
      </div>
    </div>
  </div>
</template>

<script>
import {events, providers, tools} from "../state";
import {ethers} from "ethers";
import {ERC20} from "../../../../contracts/ERC20";

export default {
  name: "Account",
  data() {
    return {
      account: null,

      metamaskError: null,
      metamaskAvailable: false,
      metamaskCorrectNetwork: false,
    }
  },
  mounted() {
    this.contractEmitter = events.on('metamask', () => {
      this.loadAccount();
    });
    this.loadAccount();
  },
  methods: {
    async loadAccount() {
      if (!tools.isMetamaskInstalled()) {
        this.metamaskError = "Metamask not installed";
        this.metamaskAvailable = false;
        this.account = null;
        return;
      }

      try {

        if (! await tools.isMetamaskConnected()) {
          this.metamaskError = "Metamask not connected";
          this.metamaskAvailable = true;
          this.account = null;
          return;
        }

        const signer = await providers.metamask.getSigner();
        const network = await providers.metamask.getNetwork();

        let networkName = network.name;
        if (network.chainId === tools.NETWORK_CHAIN_ID) networkName = "Localhost";

        this.account = {
          address: await signer.getAddress(),
          network: networkName + " [" + network.chainId + "]",
          balance: null
        };

        this.metamaskAvailable = true;

        if (network.chainId !== tools.NETWORK_CHAIN_ID) {
          this.metamaskError = "Please change network";
          this.metamaskCorrectNetwork = false;
          return;
        }

        this.metamaskError = null;
        this.metamaskCorrectNetwork = true;

        let balance = {
          eth: null,
          tokens: {}
        };

        await Promise.all([
          (async () => {
            balance.eth =
                ethers.formatEther(await providers.rpc.getBalance(this.account.address));
          })(),
          (async () => {
            const usdcToken = await ERC20.fromAddress(ERC20.USDC.getAddress(), providers.rpc);
            balance.tokens = [
              {
                token: usdcToken,
                balance: ethers.formatUnits(
                    await usdcToken.balanceOf(this.account.address),
                    usdcToken.getDecimals()
                )
              }
            ];
          })()
        ]);

        this.account.balance = balance;

      } catch (e) {
        console.error(e);
      }


    },
    async connect() {
      await tools.connectMetamask();
    },
    async disconnect() {
      await tools.disconnectMetamask();
    },
    async changeNetwork() {
      ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: process.env.CHAIN_ID,
          rpcUrls: [process.env.RPC_URL],
          chainName: process.env.NETWORK_NAME,
          nativeCurrency: {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18
          }
        }]
      });
    },
  }
}
</script>

<style scoped>

</style>