<template>
  <div class="pre-swap">
    <div class="component swap">
      <div class="component-title">Buy / sell tokens</div>

      <div class="price">
        <template v-if="price">
          1 ETH = {{ price }} USDC
        </template>
        <template v-if="!price">
          ---
        </template>
      </div>

      <div class="inputs">
        <div>
          <input type="number" class="input wide" v-model="ethAmount"
                 @change="checkETH()" @keyup="checkETH()"
                 placeholder="Enter ETH amount to buy USDC"/>
          <div class="price-hint">
            <template v-if="!buyQuote">&nbsp;</template>
            <template v-if="buyQuote">
              ~ {{ buyQuote }} USDC
            </template>
          </div>
        </div>

        <div>
          <input type="number" class="input wide" v-model="usdcAmount"
                 @change="checkUSDC()" @keyup="checkUSDC()"
                 placeholder="Enter USDC amount to sell"/>
          <div class="price-hint">
            <template v-if="!sellQuote">&nbsp;</template>
            <template v-if="sellQuote">
              ~ {{ sellQuote }} ETH
            </template>
          </div>
        </div>

      </div>

    </div>
    <div class="component-buttons two" v-if="isMetamaskOk">
      <div class="component-button" @click="buyUSDC()">Buy USDC</div>
      <div class="component-button" @click="sellUSDC()">Sell USDC</div>
    </div>
  </div>
</template>

<script>
import {events, providers, tools} from "../state";
import {UniswapFactory} from "../../../../contracts/UniswapFactory";
import {UniswapPair} from "../../../../contracts/UniswapPair";
import {ERC20} from "../../../../contracts/ERC20";
import {Uniswap} from "../../../../contracts/Uniswap";
import {ethers} from "ethers";

export default {
  name: "Swap",
  data() {
    return {
      isMetamaskOk: false,
      metamaskEmitter: null,

      price: null,

      uniswap: null,
      pair: null,

      tokenUSDC: null,
      tokenWETH: null,

      ethAmount: '',
      usdcAmount: '',

      buyQuote: null,
      sellQuote: null,

      buyQuoteTimeout: null,
      sellQuoteTimeout: null,
    }
  },
  async mounted() {
    this.metamaskEmitter = events.on('metamask', async () => {
      this.isMetamaskOk = await tools.isMetamaskOk();
    });
    this.isMetamaskOk = await tools.isMetamaskOk();

    this.uniswap = await new Uniswap(providers.rpc);

    this.tokenUSDC = await ERC20.fromAddress(ERC20.USDC.getAddress(), providers.rpc);
    this.tokenWETH = await ERC20.fromAddress(ERC20.WETH.getAddress(), providers.rpc);

    const factory = new UniswapFactory(providers.rpc);
    this.pair = new UniswapPair(
        await factory.getPair(this.tokenUSDC.getAddress(), this.tokenWETH.getAddress()),
        providers.rpc
    );

    await this.checkPrice();
  },
  methods: {
    async checkPrice() {

      let reserves = await this.pair.getReserves();
      let reserve0, reserve1;

      if (this.tokenWETH === ERC20.WETH.getAddress()) {
        reserve0 = Number(reserves[0]) / (10 ** Number(ERC20.WETH.getDecimals()));
        reserve1 = Number(reserves[1]) / (10 ** Number(ERC20.USDC.getDecimals()));
      } else {
        reserve1 = Number(reserves[0]) / (10 ** Number(ERC20.USDC.getDecimals()));
        reserve0 = Number(reserves[1]) / (10 ** Number(ERC20.WETH.getDecimals()));
      }

      this.price = reserve1 / reserve0;
    },
    async checkETH() {
      if (this.buyQuoteTimeout != null) clearTimeout(this.buyQuoteTimeout);

      this.buyQuoteTimeout = setTimeout(async () => {
        if (isNaN(parseFloat(this.ethAmount))) {
          this.buyQuote = null;
          return;
        }

        const quote = (await this.uniswap.getAmountsOut(
            BigInt(this.ethAmount * (10 ** Number(this.tokenWETH.getDecimals()))),
            [
              this.tokenWETH.getAddress(),
              this.tokenUSDC.getAddress(),
            ]
        ))[1];

        this.buyQuote = ethers.formatUnits(quote, this.tokenUSDC.getDecimals());

      }, 1000);

    },
    async checkUSDC() {
      if (this.sellQuoteTimeout != null) clearTimeout(this.sellQuoteTimeout);

      this.sellQuoteTimeout = setTimeout(async () => {
        if (isNaN(parseFloat(this.usdcAmount))) {
          this.sellQuote = null;
          return;
        }

        const quote = (await this.uniswap.getAmountsOut(
            BigInt(this.usdcAmount * (10 ** Number(this.tokenUSDC.getDecimals()))),
            [
              this.tokenUSDC.getAddress(),
              this.tokenWETH.getAddress(),
            ]
        ))[1];

        this.sellQuote = ethers.formatUnits(quote, this.tokenWETH.getDecimals());

      }, 1000);

    },
    async buyUSDC() {
      if (isNaN(parseFloat(this.ethAmount))) return;

      const eth = BigInt(this.ethAmount * (10 ** Number(this.tokenWETH.getDecimals())));

      const singer = await providers.metamask.getSigner();
      const address = await singer.getAddress();

      const uniswapSinger = new Uniswap(singer);

      await uniswapSinger.swapExactETHForTokens(
          eth,
          1n,
          [
            this.tokenWETH.getAddress(),
            this.tokenUSDC.getAddress(),
          ],
          address,
          BigInt(Math.round(60 * 60 * 1000 + new Date().getTime() / 1000))
      );

      this.ethAmount = '';
      events.emit('metamask');
      events.emit('contract');
      await this.checkPrice();

    },
    async sellUSDC() {
      if (isNaN(parseFloat(this.usdcAmount))) return;

      const usdc = BigInt(this.usdcAmount * (10 ** Number(this.tokenUSDC.getDecimals())));

      const singer = await providers.metamask.getSigner();
      const address = await singer.getAddress();

      const uniswapSinger = new Uniswap(singer);

      const allowance = await this.tokenUSDC.allowance(address, uniswapSinger.getAddress());
      if (allowance < usdc) {
        const token = await ERC20.fromAddress(this.tokenUSDC.getAddress(), singer);
        await token.approve(uniswapSinger.getAddress(), 10n ** 32n);
      }

      await uniswapSinger.swapExactTokensForETH(
          usdc,
          1n,
          [
            this.tokenUSDC.getAddress(),
            this.tokenWETH.getAddress(),
          ],
          address,
          BigInt(Math.round(60 * 60 * 1000 + new Date().getTime() / 1000))
      );

      this.usdcAmount = '';
      events.emit('metamask');
      events.emit('contract');
      await this.checkPrice();

    }
  }
}
</script>

<style scoped>

</style>