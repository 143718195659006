import { createApp } from 'vue';
import App from './vue/App.vue';
import { providers, tools } from './vue/state';
import { ethers } from "ethers";
const w = window;
if (w.ethereum)
    tools.createMetamask();
providers.rpc = ethers.getDefaultProvider(process.env.RPC_URL);
(async function app() {
    let xiveLoansAddress = require("../../.env.json").xiveLoansAddress;
    if (xiveLoansAddress == null) {
        const res = await fetch('/env-prod.json').then(res => res.text());
        xiveLoansAddress = JSON.parse(res).xiveLoansAddress;
    }
    window['xiveLoansAddress'] = xiveLoansAddress;
    createApp(App).mount('#app');
})();
