import { Contract, ethers } from "ethers";
const ERC20Abi = require("./ERC20.json");
export class ERC20 {
    static getTokens() {
        return [this.WETH, this.WBTC, this.USDC, this.USDT];
    }
    static getToken(symbol) {
        if (symbol === "WETH")
            return this.WETH;
        if (symbol === "WBTC")
            return this.WBTC;
        if (symbol === "USDC")
            return this.USDC;
        if (symbol === "USDT")
            return this.USDT;
        return null;
    }
    constructor(contractAddress, symbol, decimals, provider) {
        this.contractAddress = contractAddress;
        this.symbol = symbol;
        this.decimals = decimals;
        if (provider != null) {
            this.contract = new Contract(contractAddress, ERC20Abi, provider);
        }
        else {
            this.contract = undefined;
        }
    }
    static async fromAddress(address, provider) {
        const contract = new Contract(address, ERC20Abi, provider);
        return new ERC20(address, await contract['symbol'](), await contract['decimals'](), provider);
    }
    static getCoinDecimals(symbol) {
        let token;
        if (symbol === 'ETH') {
            token = ERC20.WETH;
        }
        else {
            token = ERC20.getToken(symbol);
        }
        return token?.getDecimals();
    }
    setContract(provider) {
        this.contract = new Contract(this.contractAddress, ERC20Abi, provider);
    }
    getAddress() {
        return this.contractAddress;
    }
    getSymbol() {
        return this.symbol;
    }
    getDecimals() {
        return this.decimals;
    }
    balanceOf(address) {
        if (this.contract == null)
            throw "Contract is not initialized";
        return this.contract['balanceOf'](address);
    }
    approve(spender, value) {
        if (this.contract == null)
            throw "Contract is not initialized";
        return this.contract['approve'](spender, value);
    }
    allowance(owner, spender) {
        if (this.contract == null)
            throw "Contract is not initialized";
        return this.contract['allowance'](owner, spender);
    }
    formatBalance(balance) {
        return ethers.formatUnits(balance, this.decimals);
    }
}
ERC20.WETH = new ERC20("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "WETH", 18n);
ERC20.WBTC = new ERC20("0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599", "WBTC", 8n);
ERC20.USDC = new ERC20("0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", "USDC", 6n);
ERC20.USDT = new ERC20("0xdAC17F958D2ee523a2206206994597C13D831ec7", "USDT", 6n);
