import { Contract } from "ethers";
const xiveLoansAbi = require("./XiveLoans.json");
export class XiveLoans {
    constructor(provider) {
        if (provider == null)
            throw "No provider provided";
        this.contractAddress = window['xiveLoansAddress'];
        this.contract = new Contract(this.contractAddress, xiveLoansAbi, provider);
    }
    async supplied(address) {
        return await this.contract['supplied'](address);
    }
    async used(address) {
        return await this.contract['used'](address);
    }
    async supply(amount) {
        return await this.contract['supply'](amount);
    }
    async withdraw(amount) {
        return await this.contract['withdraw'](amount);
    }
    async openDeal(sourceAmount) {
        return await this.contract['openDeal'](sourceAmount);
    }
    async closeDeal(dealId) {
        return await this.contract['closeDeal'](dealId);
    }
    async supplyTokenAddress() {
        return await this.contract['supplyTokenAddress']();
    }
    async targetTokenAddress() {
        return await this.contract['targetTokenAddress']();
    }
    getAddress() {
        return this.contractAddress;
    }
    async totalSupply() {
        return await this.contract['totalSupply']();
    }
    async totalBorrowed() {
        return await this.contract['totalBorrowed']();
    }
    async allSuppliers() {
        return await this.contract['allSuppliers']();
    }
    async allDeals() {
        return await this.contract['allDeals']();
    }
    async profitSharingRate() {
        return await this.contract['profitSharingRate']();
    }
    async owner() {
        return await this.contract['owner']();
    }
    async dealPeriod() {
        return await this.contract['dealPeriod']();
    }
    async profitSharingConfig() {
        return await this.contract['profitSharingConfig']();
    }
    async setDealPeriod(dealPeriod) {
        return await this.contract['setDealPeriod'](dealPeriod);
    }
    async setProfitSharingConfig(base, slope1, slope2, k) {
        return await this.contract['setProfitSharingConfig'](base, slope1, slope2, k);
    }
    async availableTarget() {
        return await this.contract['availableTarget']();
    }
    async allowedSuppliers(address) {
        return await this.contract['allowedSuppliers'](address);
    }
    async allowSupplier(address) {
        return await this.contract['allowSupplier'](address);
    }
    async denySupplier(address) {
        return await this.contract['denySupplier'](address);
    }
    async dealFee() {
        return await this.contract['dealFee']();
    }
    async setDealFee(fee) {
        return await this.contract['setDealFee'](fee);
    }
    async dealEnabled() {
        return await this.contract['dealEnabled']();
    }
    async setDealEnabled(enabled) {
        return await this.contract['setDealEnabled'](enabled);
    }
    async feeStorage() {
        return await this.contract['feeStorage']();
    }
}
