<template>
  <div class="pre-contract">
    <div class="component contract">
      <div class="component-title">Contract operations</div>

      <div class="inputs">
        <div>
          <div class="input-title">
            Supply to contract
          </div>
          <input type="number" class="input wide" placeholder="Enter USDC amount" v-model="supplyAmount"/>
        </div>
        <div>
          <div class="input-title">
            Withdraw from contract
          </div>
          <input type="number" class="input wide" placeholder="Enter USDC amount" v-model="withdrawAmount"/>
        </div>

        <div>
          <div class="input-title">
            Start a deal
          </div>
          <input type="number" class="input wide" placeholder="Enter USDC amount" v-model="dealAmount"/>
        </div>

      </div>

    </div>
    <div class="component-buttons three" v-if="isMetamaskOk">
      <div class="component-button" @click="supply()">Supply</div>
      <div class="component-button" @click="withdraw()">Withdraw</div>
      <div class="component-button" @click="openDeal()">Open Deal</div>
    </div>
  </div>
</template>

<script>
import {XiveLoans} from "../../../../contracts/XiveLoans";
import {events, providers, tools} from "../state";
import {ERC20} from "../../../../contracts/ERC20";

export default {
  name: "Contract",
  data() {
    return {
      isMetamaskOk: false,
      metamaskEmitter: null,

      supplyAmount: '',
      withdrawAmount: '',
      dealAmount: '',

      contract: null,

      usdcToken: null
    }
  },
  async mounted() {
    this.metamaskEmitter = events.on('metamask', async () => {
      this.isMetamaskOk = await tools.isMetamaskOk();
    });
    this.isMetamaskOk = await tools.isMetamaskOk();

    this.usdcToken = await ERC20.fromAddress(ERC20.USDC.getAddress(), providers.rpc);
  },
  methods: {
    async supply() {
      if (isNaN(this.supplyAmount)) return;
      const signer = await providers.metamask.getSigner();
      const address = await signer.getAddress();
      const contract = new XiveLoans(signer);

      const usdc = BigInt(Math.round(
          this.supplyAmount * (10 ** Number(this.usdcToken.getDecimals()))
      ));
      const allowance = (await this.usdcToken.allowance(address, contract.getAddress()));
      if (allowance < usdc) {
        const token = await ERC20.fromAddress(this.usdcToken.getAddress(), signer);
        await token.approve(contract.getAddress(), 10n ** 32n);
      }

      await contract.supply(usdc);
      events.emit("contract");
      events.emit("metamask");
      this.supplyAmount = '';

    },
    async withdraw() {
      if (isNaN(this.withdrawAmount)) return;
      const signer = await providers.metamask.getSigner();
      const contract = new XiveLoans(signer);

      const usdc = BigInt(Math.round(
          this.withdrawAmount * (10 ** Number(this.usdcToken.getDecimals()))
      ));

      await contract.withdraw(usdc);
      events.emit("contract");
      events.emit("metamask");
      this.withdrawAmount = '';

    },
    async openDeal() {
      if (isNaN(this.dealAmount)) return;
      const signer = await providers.metamask.getSigner();
      const address = await signer.getAddress();
      const contract = new XiveLoans(signer);

      const usdc = BigInt(Math.round(
          this.dealAmount * (10 ** Number(this.usdcToken.getDecimals()))
      ));
      const allowance = (await this.usdcToken.allowance(address, contract.getAddress()));
      if (allowance < usdc) {
        const token = await ERC20.fromAddress(this.usdcToken.getAddress(), signer);
        await token.approve(contract.getAddress(), 10n ** 32n);
      }

      await contract.openDeal(usdc);
      events.emit("contract");
      events.emit("metamask");
      this.supplyAmount = '';

    },
  }
}
</script>

<style scoped>

</style>