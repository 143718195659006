import { Contract } from "ethers";
const uniswapFactoryAbi = require("./UniswapFactory.json");
export class UniswapFactory {
    constructor(provider) {
        this.contract = new Contract(UniswapFactory.ADDRESS, uniswapFactoryAbi, provider);
    }
    async getPair(address1, address2) {
        return await this.contract['getPair'](address1, address2);
    }
}
UniswapFactory.ADDRESS = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
