import { Contract } from "ethers";
const uniswapAbi = require("./Uniswap.json");
export class Uniswap {
    constructor(provider) {
        this.contract = new Contract(Uniswap.ADDRESS, uniswapAbi, provider);
    }
    getAddress() {
        return Uniswap.ADDRESS;
    }
    async quote(amountA, reserveA, reserveB) {
        return await this.contract['quote'](amountA, reserveA, reserveB);
    }
    async swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline) {
        return await this.contract['swapExactTokensForTokens'](amountIn, amountOutMin, path, to, deadline);
    }
    async swapExactETHForTokens(payable, amountOutMin, path, to, deadline) {
        return await this.contract['swapExactETHForTokens'](amountOutMin, path, to, deadline, {
            value: payable
        });
    }
    async swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline) {
        return await this.contract['swapExactTokensForETH'](amountIn, amountOutMin, path, to, deadline);
    }
    async getAmountsOut(amountIn, path) {
        return await this.contract['getAmountsOut'](amountIn, path);
    }
    async getAmountsIn(amountOut, path) {
        return await this.contract['getAmountsIn'](amountOut, path);
    }
}
Uniswap.ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
