<template>
  <div class="component summary">
    <div class="component-title">Contract state</div>

    <div class="block blue">
      <div class="block-title">
        Contract management
      </div>
      <template v-if="!editContract">
        <div class="block-items" v-if="data?.owner && data.owner !== accountAddress">
          <div class="block-item">
            <div class="item-title">
              Contract owner
            </div>
            <div>
              {{ data.owner }}
            </div>
          </div>
        </div>
        <div class="block-items top-border" v-if="data?.dealPeriod">
          <div class="block-item">
            <div class="item-title">
              Deal period
            </div>
            <div>
              {{ data.dealPeriod }} minutes
            </div>
          </div>
        </div>
        <div class="block-items top-border" v-if="data?.profitSharingConfig">
          <div class="block-item">
            <div class="item-title">
              Base
            </div>
            <div>
              {{ data.profitSharingConfig.base }} %
            </div>
          </div>
          <div class="block-item">
            <div class="item-title">
              Slope 1
            </div>
            <div>
              {{ data.profitSharingConfig.slope1 }} %
            </div>
          </div>
          <div class="block-item">
            <div class="item-title">
              Slope 2
            </div>
            <div>
              {{ data.profitSharingConfig.slope2 }} %
            </div>
          </div>
          <div class="block-item">
            <div class="item-title">
              K
            </div>
            <div>
              {{ data.profitSharingConfig.k }} %
            </div>
          </div>
        </div>
        <div class="block-items top-border" v-if="data?.allowedSuppliers">
          <div class="block-item">
            <div class="item-title">
              Allowed suppliers
            </div>
            <div>
              <div v-for="account in data.allowedSuppliers">
                {{ account }}
              </div>
            </div>
          </div>
        </div>
        <div class="block-items top-border">
          <div class="block-item" v-if="data?.fee">
            <div class="item-title">
              Fee
            </div>
            <div>
              {{ data.fee }} %
            </div>
          </div>
          <div class="block-item" v-if="data?.enabled !== undefined">
            <div class="item-title">
              Enabled
            </div>
            <div>
              {{ data.enabled }}
            </div>
          </div>
        </div>
        <div class="block-items top-border"></div>
        <div class="edit-contract" @click="startEditContract()" v-if="data?.owner === accountAddress">
          Edit contract
        </div>
      </template>
      <template v-if="editContract">
        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Deal period
            </div>
            <div>
              <input class="input wide" type="number" v-model="editContract.dealPeriod" />
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="saveDealPeriod()">
          Save deal period
        </div>
        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Base
            </div>
            <div>
              <input class="input wide" type="number" v-model="editContract.profitSharingConfig.base" />
            </div>
          </div>
          <div class="block-item padding">
            <div class="item-title">
              Slope 1
            </div>
            <div>
              <input class="input wide" type="number" v-model="editContract.profitSharingConfig.slope1" />
            </div>
          </div>
          <div class="block-item padding">
            <div class="item-title">
              Slope 2
            </div>
            <div>
              <input class="input wide" type="number" v-model="editContract.profitSharingConfig.slope2" />
            </div>
          </div>
          <div class="block-item padding">
            <div class="item-title">
              K
            </div>
            <div>
              <input class="input wide" type="number" v-model="editContract.profitSharingConfig.k" />
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="saveProfitSharingConfig()">
          Save profit sharing configuration
        </div>

        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Add allowed supplier
            </div>
            <div>
              <input class="input wide" type="text" v-model="editContract.addAllowedSupplierValue" />
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="addAllowedSupplier()">
          Add supplier
        </div>

        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Remove allowed supplier
            </div>
            <div>
              <input class="input wide" type="text" v-model="editContract.removeAllowedSupplierValue" />
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="removeAllowedSupplier()">
          Remove supplier
        </div>

        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Deal fee
            </div>
            <div>
              <input class="input wide" type="number" step="0.01" v-model="editContract.fee" />
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="saveDealFee()">
          Save deal fee
        </div>

        <div class="block-items top-border">
          <div class="block-item padding">
            <div class="item-title">
              Deal enabled
            </div>
            <div>
              <select class="input wide" v-model="editContract.enabled">
                <option :value="false">Disabled</option>
                <option :value="true">Enabled</option>
              </select>
            </div>
          </div>
        </div>
        <div class="edit-contract top-margin" @click="saveDealEnabled()">
          Save deal enabled
        </div>
      </template>
    </div>

    <div class="tokens" v-if="data?.balance != null">
      <div class="token" data-token="ETH">
        {{ data.balance.eth }}
        ETH
      </div>
      <div class="token" :data-token="data.supplyTokenSymbol">
        {{ data.balance.supplyToken }}
        {{ data.supplyTokenSymbol }}
      </div>
      <div class="token" :data-token="data.targetTokenSymbol" v-if="data.balance.targetToken">
        {{ data.balance.targetToken }}
        {{ data.targetTokenSymbol }}
      </div>
    </div>

    <div class="block orange" v-if="data?.profitSharingRate">
      <div class="block-title single">
        Profit sharing:
        {{ data.profitSharingRate }} %
      </div>
    </div>
    <br/>

    <div class="block blue" v-if="data?.feeStorage !== undefined">
      <div class="block-title single">
        Fee storage:
        {{ data.feeStorage }} USDC
      </div>
    </div>
    <br/>
    <div class="block green" v-if="data?.supply">
      <div class="block-title">
        Supply: {{ data.supply.totalSupply }}
        {{ data.supplyTokenSymbol }}
      </div>
      <div class="block-items">
        <div class="block-item" v-for="item in data.supply.suppliers" :title="item.address" >
          <div class="gray">{{ item.shortAddress }}</div>
          {{ item.value }} {{ data.supplyTokenSymbol }}
        </div>
      </div>
    </div>
    <br/>
    <div class="block" v-if="data?.deals">
      <div class="block-title">
        Borrowed:
        {{ data.deals.totalBorrowed }}
        {{ data.supplyTokenSymbol }}
      </div>
      <div class="block-rows">
        <div class="block-row" v-for="deal in data.deals.items"
             :class="deal.closeSourceAmount ? 'closed ' : ' '">
          <div class="items two items-head">
            <div>
              <div class="address">
                {{ deal.borrower }}: {{ deal.sourceAmount / 2 }} {{ data.supplyTokenSymbol }}
              </div>
            </div>
            <div>
              <div class="maxDate">
                <template v-if="deal.closeSourceAmount">
                  <b>
                    Closed:
                    <span :class="deal.borrowerReturn < deal.sourceAmount / 2 ? 'text-loss' : 'text-profit'">
                    {{ deal.borrowerReturn }} {{ data.supplyTokenSymbol }}
                    </span>
                  </b>
                </template>
                <template v-if="!deal.closeSourceAmount">
                  {{ deal.maxDate }}
                </template>
              </div>
            </div>
          </div>
          <div class="items three">
            <div class="hint">
              OPEN
            </div>
            <div>
              <div class="supplier-share" title="Profit sharing">
                {{ deal.supplierShare }} %
              </div>
            </div>
            <div class="hint">
              <template v-if="deal.closeSourceAmount">
                CLOSE
              </template>
              <template v-if="!deal.closeSourceAmount">
                CURRENT
              </template>
            </div>
          </div>
          <div class="items three">
            <div>
              <b>{{ deal.sourceAmount }} {{ data.supplyTokenSymbol }}</b>
            </div>
            <div>
              <div :class="deal.profit > 0 ? 'profit' : 'loss'">
                <b>
                  {{ deal.profit }} {{ data.supplyTokenSymbol }}
                </b>
              </div>
            </div>
            <div>
              <template v-if="deal.closeSourceAmount">
                <b>{{ deal.closeSourceAmount }} {{ data.supplyTokenSymbol }}</b>
              </template>
              <template v-if="deal.predictCloseSourceAmount">
                ~ {{ deal.predictCloseSourceAmount }} {{ data.supplyTokenSymbol }}
              </template>
            </div>
          </div>
          <div class="items three">
            <div class="eth-price">
              1 WETH = {{ deal.openPrice }} {{ data.supplyTokenSymbol }}
            </div>
            <div>
              <div class="eth-amount">
                {{ deal.targetAmountText }} {{ data.targetTokenSymbol }}
              </div>
            </div>
            <div class="eth-price">
              1 WETH {{ deal.closePrice }} {{ data.supplyTokenSymbol }}
            </div>
          </div>
          <div class="pre-suppliers" @click="deal.suppliersOpened = true"
               v-if="!deal.suppliersOpened">
            SHOW SUPPLIERS
          </div>
          <div class="pre-suppliers" @click="deal.suppliersOpened = false"
               v-if="deal.suppliersOpened">
            HIDE SUPPLIERS
          </div>
          <div :class="'suppliers ' +  (deal.suppliersOpened ? 'opened' : '')">
            <div class="items" :data-count="deal.suppliers.length">
              <div v-for="supplier in deal.suppliers" :title="supplier.shortAddress">
                {{ supplier.shortAddress }}
                <br/>
                {{ supplier.value }} {{ data.supplyTokenSymbol }}
                <div v-if="supplier.returnValue">
                  Returned: {{ supplier.returnValue }} {{ data.supplyTokenSymbol }}
                </div>
              </div>
            </div>
          </div>
          <div class="close-deal" @click="closeDeal(deal.dealId)"
               v-if="!deal.closeSourceAmount && deal.borrower === accountAddress">
            CLOSE DEAL
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { providers, events, tools } from "../state";
import {XiveLoans} from "../../../../contracts/XiveLoans";
import {ERC20} from "../../../../contracts/ERC20";
import {ethers} from "ethers";
export default {
  name: "Summary",
  data() {
    return {
      contractEmitter: null,

      accountEmitter: null,
      accountAddress: null,

      data: null,

      editContract: null,
    }
  },
  mounted() {
    this.contractEmitter = events.on('contract', () => {
      this.loadContract();
    });
    this.loadContract();

    this.accountEmitter = events.on('metamask', () => {
      this.loadAccount();
    });
    this.loadAccount();
  },
  methods: {
    async loadAccount() {
      if (!await tools.isMetamaskOk()) {
        this.accountAddress = null;
        return;
      }

      const signer = await providers.metamask.getSigner();
      this.accountAddress = await signer.getAddress();

    },
    async loadContract() {
      this.data = null;

      const data = {
        owner: null,
        dealPeriod: null,

        profitSharingConfig: null,

        balance: {
          eth: null,
          supplyToken: null,
          targetToken: null
        },
        supplyTokenSymbol: null,
        targetTokenSymbol: null,

        profitSharingRate: null,

        supply: {
          totalSupply: null,
          suppliers: null
        },

        deals: {
          totalBorrowed: null,
          items: null
        }
      };

      const contract = new XiveLoans(providers.rpc);
      let supplyToken = null;
      let targetToken = null;

      await Promise.all([
        (async () => {
          data.owner = await contract.owner();
        })(),
        (async () => {
          data.dealPeriod = (await contract.dealPeriod()) / 60n;
        })(),
        (async () => {
          const config = await contract.profitSharingConfig();
          data.profitSharingConfig = {
            base: Number(config[0]) / 100,
            slope1: Number(config[1]) / 100,
            slope2: Number(config[2]) / 100,
            k: Number(config[3]) / 100
          };
        })(),
        (async () => {
          supplyToken = await ERC20.fromAddress(
              await contract.supplyTokenAddress(),
              providers.rpc
          );

          data.supplyTokenSymbol = supplyToken.getSymbol();
          data.balance.supplyToken = ethers.formatUnits(
              await supplyToken.balanceOf(contract.getAddress()),
              supplyToken.getDecimals()
          );
        })(),
        (async () => {
          targetToken = await ERC20.fromAddress(
              await contract.targetTokenAddress(),
              providers.rpc
          );
          if (targetToken.getSymbol() === 'WETH') {
            data.targetTokenSymbol = 'ETH';
            data.balance.targetToken = null;
          } else {
            data.targetTokenSymbol = targetToken.getSymbol();
            data.balance.targetToken = ethers.formatUnits(
                await targetToken.balanceOf(contract.getAddress()),
                targetToken.getDecimals()
            );
          }
        })(),
        (async () => {
          data.balance.eth = ethers.formatEther(
              await providers.rpc.getBalance(contract.getAddress())
          );
        })(),
      ]);

      this.data = data;

      await Promise.all([
        (async () => {
          this.data.profitSharingRate = Number(await contract.profitSharingRate()) / 100;
        })(),
        (async () => {
          const suppliers = await contract.allSuppliers();
          const totalSupply = suppliers.map(s => s.value).reduce((sum, val) => sum + val, 0n);

          this.data.supply = {
            totalSupply: ethers.formatUnits(totalSupply, supplyToken.getDecimals()),
            suppliers: suppliers.map(
                item => {
                  return {
                    address: item.supplier,
                    shortAddress: item.supplier.substring(0, 10),
                    value: ethers.formatUnits(item.value, supplyToken.getDecimals())
                  }
                }
            )
          };

        })(),
        (async () => {
          const deals = await contract.allDeals();
          const totalBorrowed = await contract.totalBorrowed();

          this.data.deals = {
            totalBorrowed: ethers.formatUnits(totalBorrowed, supplyToken.getDecimals()),
            items: deals.map(d => {
              return {
                dealId: d.dealId,

                borrower: d.borrower,

                sourceAmount: ethers.formatUnits(d.sourceAmount, supplyToken.getDecimals()),
                targetAmount: ethers.formatUnits(d.targetAmount, targetToken.getDecimals()),

                targetAmountText: Math.round(1_000_000 * Number(d.targetAmount) / Number(10n ** targetToken.getDecimals())) / 1_000_000,

                suppliers: d.suppliers.map((s, i) => {
                  return {
                    address: s,
                    shortAddress: s.substring(0, 8),
                    value: ethers.formatUnits(d.suppliersAmount[i], supplyToken.getDecimals()),
                    returnValue: d.suppliersReturn[i] > 0n ?
                        ethers.formatUnits(d.suppliersReturn[i], supplyToken.getDecimals()) : null,
                  };
                }),

                maxDate: tools.formatDate(new Date(Number(d.maxDate * 1000n))),
                supplierShare: Number(d.supplierShare) / 100,

                closeSourceAmount:
                    d.closeSourceAmount > 0n ?
                        ethers.formatUnits(d.closeSourceAmount, supplyToken.getDecimals()) : null,

                profit: ethers.formatUnits((() => {
                  if (d.closeSourceAmount > 0n) {
                    return d.closeSourceAmount - d.sourceAmount;
                  } else {
                    return d.predictCloseSourceAmount - d.sourceAmount;
                  }
                })(), supplyToken.getDecimals()),

                predictCloseSourceAmount:
                    d.predictCloseSourceAmount > 0n ?
                        ethers.formatUnits(d.predictCloseSourceAmount, supplyToken.getDecimals()) : null,

                openPrice:
                    Math.round(100 * (Number(d.sourceAmount) / Number(10n ** supplyToken.getDecimals())) /
                    (Number(d.targetAmount) / Number(10n ** targetToken.getDecimals()))) / 100.0,

                closePrice:
                    d.predictCloseSourceAmount > 0n ?
                        '~ ' + Math.round(100 * (Number(d.predictCloseSourceAmount) / Number(10n ** supplyToken.getDecimals())) /
                            (Number(d.targetAmount) / Number(10n ** targetToken.getDecimals()))) / 100.0 :
                        '= ' + Math.round(100 * (Number(d.closeSourceAmount) / Number(10n ** supplyToken.getDecimals())) /
                            (Number(d.targetAmount) / Number(10n ** targetToken.getDecimals()))) / 100.0,

                suppliersOpened: false,

                borrowerReturn: ethers.formatUnits(d.borrowerReturn, supplyToken.getDecimals()),

              };
            })
          };

        })(),
        (async () => {
          const contract = new XiveLoans(providers.rpc);
          const allowedSuppliers = [];
          let accounts = [
            '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
            '0x70997970C51812dc3A010C7d01b50e0d17dc79C8',
            '0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC',
            '0x90F79bf6EB2c4f870365E785982E1f101E93b906',
          ];

          for (const account of accounts) {
            if (await contract.allowedSuppliers(account)) {
              allowedSuppliers.push(account);
            }
          }

          this.data.allowedSuppliers = allowedSuppliers;
        })(),
        (async () => {
          const contract = new XiveLoans(providers.rpc);

          this.data.fee = Number(await contract.dealFee()) / 100.0;
        })(),
        (async () => {
          const contract = new XiveLoans(providers.rpc);

          this.data.enabled = await contract.dealEnabled();
        })(),
        (async () => {
          const contract = new XiveLoans(providers.rpc);

          this.data.feeStorage = Number(await contract.feeStorage()) / (10 ** 6);
        })(),
      ]);

    },
    async closeDeal(dealId) {
      if (!await tools.isMetamaskOk()) return;

      const signer = await providers.metamask.getSigner();

      const contract = new XiveLoans(signer);
      await contract.closeDeal(dealId);

      events.emit('contract');
      events.emit('metamask');

    },
    startEditContract() {
      this.editContract = {
        dealPeriod: this.data.dealPeriod,
        profitSharingConfig: this.data.profitSharingConfig,
        addAllowedSupplierValue: '',
        removeAllowedSupplierValue: '',
        fee: this.data.fee,
        enabled: this.data.enabled
      }
    },
    async saveDealPeriod() {
      if (isNaN(this.editContract.dealPeriod)) return;
      const singer = await providers.metamask.getSigner();
      const contract = new XiveLoans(singer);
      await contract.setDealPeriod(BigInt(this.editContract.dealPeriod) * 60n);
      await this.loadContract();
      this.editContract = null;
    },
    async saveProfitSharingConfig() {
      if (isNaN(this.editContract.profitSharingConfig.base)) return;
      if (isNaN(this.editContract.profitSharingConfig.slope1)) return;
      if (isNaN(this.editContract.profitSharingConfig.slope2)) return;
      if (isNaN(this.editContract.profitSharingConfig.k)) return;

      const singer = await providers.metamask.getSigner();
      const contract = new XiveLoans(singer);
      await contract.setProfitSharingConfig(
          BigInt(this.editContract.profitSharingConfig.base * 100),
          BigInt(this.editContract.profitSharingConfig.slope1 * 100),
          BigInt(this.editContract.profitSharingConfig.slope2 * 100),
          BigInt(this.editContract.profitSharingConfig.k * 100),
      )
      await this.loadContract();
      this.editContract = null;
    },
    async addAllowedSupplier() {
      if (!this.editContract.addAllowedSupplierValue) return;

      const xiveLoans = new XiveLoans(await providers.metamask.getSigner());
      await xiveLoans.allowSupplier(this.editContract.addAllowedSupplierValue);
      this.editContract = null;
    },
    async removeAllowedSupplier() {
      if (!this.editContract.removeAllowedSupplierValue) return;

      const xiveLoans = new XiveLoans(await providers.metamask.getSigner());
      await xiveLoans.denySupplier(this.editContract.removeAllowedSupplierValue);
      this.editContract = null;
    },
    async saveDealFee() {
      const xiveLoans = new XiveLoans(await providers.metamask.getSigner());
      await xiveLoans.setDealFee(BigInt(this.editContract.fee * 100));
      this.editContract = null;
    },
    async saveDealEnabled() {
      const xiveLoans = new XiveLoans(await providers.metamask.getSigner());
      await xiveLoans.setDealEnabled(this.editContract.enabled);
      this.editContract = null;
    }
  }
}
</script>

<style scoped>

</style>