<template>
  <div class="app-grid">
    <Account/>
    <Contract/>
    <div>
      <Swap/>
    </div>
    <div>
      <Summary/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Summary from "./components/Summary.vue";
import Account from "./components/Account.vue";
import Contract from "./components/Contract.vue";
import Swap from "./components/Swap.vue";
export default defineComponent({
  name: 'App',
  components: {
    Swap,
    Contract,
    Account,
    Summary,
  },
});
</script>

<style scoped>

</style>