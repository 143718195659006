import { Contract } from "ethers";
const uniswapPairAbi = require("./UniswapPair.json");
export class UniswapPair {
    constructor(address, provider) {
        this.address = address;
        this.contract = new Contract(address, uniswapPairAbi, provider);
    }
    getAddress() {
        return this.address;
    }
    getContract() {
        return this.contract;
    }
    async getToken0() {
        return await this.contract['token0']();
    }
    async getToken1() {
        return await this.contract['token1']();
    }
    async getReserves() {
        return await this.contract['getReserves']();
    }
}
